exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-animation-js": () => import("./../../../src/pages/animation.js" /* webpackChunkName: "component---src-pages-animation-js" */),
  "component---src-pages-case-study-sodexo-js": () => import("./../../../src/pages/case_study/sodexo.js" /* webpackChunkName: "component---src-pages-case-study-sodexo-js" */),
  "component---src-pages-condev-js": () => import("./../../../src/pages/condev.js" /* webpackChunkName: "component---src-pages-condev-js" */),
  "component---src-pages-film-js": () => import("./../../../src/pages/film.js" /* webpackChunkName: "component---src-pages-film-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-graphics-js": () => import("./../../../src/pages/graphics.js" /* webpackChunkName: "component---src-pages-graphics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-sites-js": () => import("./../../../src/pages/sites.js" /* webpackChunkName: "component---src-pages-sites-js" */),
  "component---src-pages-storyboard-js": () => import("./../../../src/pages/storyboard.js" /* webpackChunkName: "component---src-pages-storyboard-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

